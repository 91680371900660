var scriptNowPlaying = {

  execute: function() {
    this.bindEvents();
  },

  bindEvents: function() {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  },

  onNowPlayingInfoChange : function(params){

    if(!window.isIOSMobileDevice && 'MusicControls' in window){
      // THis is only use in Android
      if(params && params.detail ){

        if((!MusicControls._previousControlInfoParams || JSON.stringify(MusicControls._previousControlInfoParams) !== JSON.stringify(params.detail))){
          var onSuccess = function(){

          };

          var onError = function(){

          };

          var opts = params.detail;
          var songTitle = opts[0];
          var artistName = opts[1];
          var albumName = opts[2];
          var albumId = opts[3];
          var coverArtBase64 = opts[4];
          var albumURL = opts[5];
          var audioPlaying = opts[6];
          var musicControlInfo = {
            track       : songTitle,
            artist      : artistName,
            album       : albumName,
            cover       : albumURL,
            isPlaying   : audioPlaying,                      
            dismissable : true,                     
            hasPrev   : false,//window.trebble && window.trebble.globalHelpers && window.trebble.globalHelpers.playerModel? window.trebble.globalHelpers.playerModel.isPreviousCommandAllowed(): true,      
            hasNext   : true,      
            hasClose  : false,     
            ticker    : 'Now playing "'+songTitle+' -  '+ artistName +'"',

          };

          this.createMusicControl(musicControlInfo);

          MusicControls._musicControlInfo =  musicControlInfo;
          MusicControls._previousControlInfoParams = opts;
          MusicControls.updateIsPlaying(audioPlaying);
        }


        
        if(!window._foregroundNotificationEnabled){
          if('cordova' in window && cordova.plugins && cordova.plugins.backgroundMode){

            if(window.waitForCordovaToLoad ){
              try{
                if('cordova' in window && cordova.plugins && cordova.plugins.backgroundMode){
                  cordova.plugins.backgroundMode.enable();
                  cordova.plugins.backgroundMode.on('activate', function() {
                    cordova.plugins.backgroundMode.disableWebViewOptimizations(); 
                    console.log("Disable webview optimizations");
                  });
                }
              }catch(error){
                console.error("Failed enabling background plugin. Error:"+error);
              }
              cordova.plugins.backgroundMode.setDefaults({
                title: "Trebble FM",
                text: "Trebble FM is running ...",
                icon: 'trebble_bb_logo',
                color: "DE6379", 
                hidden: true,
                silent: window.isIOSMobileDevice,
                showWhen: false 
              })
              window._foregroundNotificationEnabled = true;
            }
          }
        }
      }


    }

  },

  onQueueFinishPlaying : function(){
    if(window.waitForCordovaToLoad ){
      if(window._foregroundNotificationEnabled){
        if('cordova' in window && cordova.plugins && cordova.plugins.backgroundMode){
          cordova.plugins.backgroundMode.disable();
          window._foregroundNotificationEnabled = false;
        }
      }
    }
  },


  setupRemoteCommnandListener : function(){
    function events(action) {

      var message = JSON.parse(action).message;
      switch(message) {
        case 'music-controls-next':
        if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('next');
       }
       break;
       case 'music-controls-media-button-next':
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('next');
       }
       break;
       case 'music-controls-previous':
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('previous');
       }
       break;
       case 'music-controls-media-button-previous':
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('previous');
       }
       break;
       case 'music-controls-pause':
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('pause');
       }
       break;
       case 'music-controls-media-button-pause':
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('pause');
       }
       break;
       case 'music-controls-play':
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('play');
       }
       break;
       case 'music-controls-media-button-play-pause':
        if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('playpause');
       }
       break;
       case 'music-controls-media-button-play':
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('play');
       }
       break;
       case 'music-controls-media-button-headset-hook':
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('pause');
       }
       break;
       case 'music-controls-destroy':
       MusicControls.destroy(function(){}, function(){});
       break;

       case 'music-controls-media-button-destroy':
       MusicControls.destroy(function(){}, function(){});
       break;
       // External controls (iOS only)
       case 'music-controls-toggle-play-pause' :
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('playpause');
       }
       break;

       // Headset events (Android only)
       // All media button events are listed below
       case 'music-controls-media-button' :
       // Do something
       break;
       case 'music-controls-headset-unplugged':
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('pause');
       }
       break;
       case 'music-controls-headset-plugged':
       /*if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('play');
       }*/
       break;
       default:
       break;
     }
   }

   // Register callback
   MusicControls.subscribe(events);

   // Start listening for events
   // The plugin will run the events function each time an event is fired
   MusicControls.listen();

 },

 onAudioStopped : function(){
  if(!window.isIOSMobileDevice && 'MusicControls' in window){
   var newMusicControlInfo = MusicControls._musicControlInfo;
   if(newMusicControlInfo){
    newMusicControlInfo.isPlaying  = false;
    //console.log("newMusicControlInfo onAudioStopped"+JSON.stringify(newMusicControlInfo));
    this.createMusicControl(newMusicControlInfo);
    //MusicControls.create(newMusicControlInfo, function(){}, function(){});
    MusicControls._musicControlInfo =  newMusicControlInfo;
  }
  MusicControls.updateIsPlaying(false);
}
},

onAudioPaused : function(){
  if(!window.isIOSMobileDevice && 'MusicControls' in window){
    var newMusicControlInfo = MusicControls._musicControlInfo;
    if(newMusicControlInfo){
      newMusicControlInfo.isPlaying  = false;
      //console.log("newMusicControlInfo onAudioPaused"+JSON.stringify(newMusicControlInfo));
      this.createMusicControl(newMusicControlInfo);
      //MusicControls.create(newMusicControlInfo, function(){}, function(){});
      MusicControls._musicControlInfo =  newMusicControlInfo;
    }
    MusicControls.updateIsPlaying(false);
  }
},

onAudioElapsedTimeChanged : function(params){
  if(!window.isIOSMobileDevice && 'MusicControls' in window){
    if(params && params.detail){
      var duration = params.detail.duration;
      var position = params.detail.position;
      if(duration && duration != -1){
       var newMusicControlInfo = MusicControls._musicControlInfo;
       if(newMusicControlInfo){
        newMusicControlInfo.duration  = Math.round(duration/1000);
        newMusicControlInfo.elapsed  = Math.round(position/1000);
        //console.log("newMusicControlInfo onAudioElapsedTimeChanged"+JSON.stringify(newMusicControlInfo));
        this.createMusicControl(newMusicControlInfo);
        //MusicControls.create(newMusicControlInfo, function(){}, function(){});
        MusicControls._musicControlInfo =  newMusicControlInfo;
      }
    }
  }
}
},

createMusicControl : function(params){
  if("Promise" in window){
    if(this._isMusicControlBeingCreatedPromise){
      this._isMusicControlBeingCreatedPromise.then(function(){
        return new Promise(function(resolve, reject){
         MusicControls.create(params, resolve,reject);
       })
      }).then(function(){
        this._isMusicControlBeingCreatedPromise = null;
      }).catch(function(){
        this._isMusicControlBeingCreatedPromise = null;
      })
    }else{
     var p =  new Promise(function(resolve, reject){
       MusicControls.create(params, resolve,reject);
     }).then(function(){
      this._isMusicControlBeingCreatedPromise = null;
    }).catch(function(){
      this._isMusicControlBeingCreatedPromise = null;
    })
    this._isMusicControlBeingCreatedPromise = p;
    return p;
  }
}else{
  return MusicControls.create(params, function(){}, function(){});
}
},



onDeviceReady: function() {
  var isIOSMobileDevice = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  if(!isIOSMobileDevice && 'MusicControls' in window){
    document.addEventListener('updateMetaData', this.onNowPlayingInfoChange.bind(this), false);
    document.addEventListener('audioStopped', this.onAudioStopped.bind(this), false);
    document.addEventListener('onQueueFinishedPlaying', this.onQueueFinishPlaying.bind(this), false);
    document.addEventListener('audioPaused', this.onAudioPaused.bind(this), false);
    //document.addEventListener('audioElapsedTimeChanged',this.onAudioElapsedTimeChanged, false);

    this.setupRemoteCommnandListener();
  }
},

};

scriptNowPlaying.execute();
